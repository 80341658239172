import {ref} from "vue";
import InvoiceService from "@/service/InvoiceService";
import {useToast} from "primevue/usetoast";


export function useInvoiceList() {
    const invoices = ref([]);
    const totalInvoices = ref(0);
    const invoiceService = new InvoiceService();

    const getInvoices = async (query) => {
        const res = await invoiceService.getAll(query)
        const data = res.data
        invoices.value = data.invoices
        totalInvoices.value = data.total
    }

    return {invoices, totalInvoices, getInvoices}
}

export function useInvoiceDetail() {
    const data = ref({})
    const invoiceService = new InvoiceService()

    const getDetail = async (id) => {
        const res = await invoiceService.getOne(id)
        data.value = res.data
    }

    const editOperationalFee = async (id, operationalFee) => {
        await invoiceService.editOperationalFee(id, operationalFee)
        await getDetail(id)
    }

    return {data, getDetail, editOperationalFee}
}

export function useBookings() {
    const STATUS_APPROVE = 1
    const STATUS_DENY = 2

    const list = ref([])
    const statusUpdated = ref([])
    const invoiceService = new InvoiceService()
    const toast = useToast()


    const getList = async (id) => {
        const res = await invoiceService.getBookings(id)
        list.value = res.data
    }

    const updateBookingsStatus = async (invoiceID) => {
        const res = await invoiceService.updateBookingStatuses(invoiceID, {booking_list: statusUpdated.value})
        if (res.status === 200) {
            toast.add({severity: 'success', closable: true, summary: 'Success', detail: 'Success Update Booking Status', life: 3000})
        }
    }

    const updateBookingStatus = async (bookingID) => {
        const res = await invoiceService.updateBookingStatus(bookingID)
        if (res.status === 200) {
            toast.add({
                severity: 'success',
                closable: true,
                summary: 'Success',
                detail: res.data.message,
                life: 5000,
            })
        } else {
            toast.add({
                severity: 'error',
                closable: true,
                summary:'Error',
                detail: res.data.message,
                life: 5000,
            })
        }
        return res.status
    }

    return {list, statusUpdated, STATUS_APPROVE, STATUS_DENY, getList, updateBookingsStatus, updateBookingStatus}
}

export function generatePayment() {
    const invoiceService = new InvoiceService()
    const trx_code = ref('')
    const qris = ref({})

    const generateQRIS = async (invoice_id, trx_code) => {
        const res = await invoiceService.createQRIS(parseInt(invoice_id))
        
        if(res.status === 200) {
            return getQRIS(invoice_id, trx_code)
        }
    }

    const getQRIS = async (invoice_id, trx_code) => {
        const res = await invoiceService.getQRISDetail(invoice_id, trx_code)
        if (res.status == 200) {
            qris.value = res.data.data
        }
    }

    return {trx_code, qris, generateQRIS, getQRIS}
}